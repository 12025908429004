  import React, { useState, useEffect } from 'react';
  import './BusinessInfo.css';
  import yourLogo from './ap-logo-4.png';
  import { useNavigate } from 'react-router-dom';
  import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import { useGlobal } from './GlobalContext';

  const BusinessInfo = () => {
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const { setIsBusinessInfoComplete } = useGlobal();

    const [jobRole, setJobRole] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    // const [allowedEmails, setAllowedEmails] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [activeTab, setActiveTab] = useState('personalInfo');
    const [businessName, setBusinessName] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [postCode, setPostCode] = useState('');
    const tabs = ['personalInfo', 'businessInfo', 'invoiceDetails', 'additionalDetails'];
    const [venues, setVenues] = useState([{
      deliveryOption: 'pickup',
      collectorName: '',
      collectorEmail: '',
      collectorPhone: '',
      address: '', // Added venue address
      postCode: '', // Added venue post code
      instructions: '', // Added delivery instructions
    }]);
    const [clubTimings, setClubTimings] = useState({ from: '', to: '' });
    const [lunchTime, setLunchTime] = useState({ from: '', to: '' });
    const [deliveryWindow, setDeliveryWindow] = useState({ from: '', to: '' });
    const [ageRange, setAgeRange] = useState({ lower: '', upper: '' });
    const [fullName, setFullName] = useState('');
    const [isDeliveryWindowConfirmed, setIsDeliveryWindowConfirmed] = useState(false);
    const [legalName, setLegalName] = useState('');
    const [legalAddress, setLegalAddress] = useState('');
    const [invoiceEmail, setInvoiceEmail] = useState('');
    const [confirmationChecked, setConfirmationChecked] = useState(false);
    const [confirmationError, setConfirmationError] = useState('');
    
    useEffect(() => {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const docRef = doc(db, "ClientContactInfo", uid);
        getDoc(docRef)
          .then((docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              setFullName(data.fullName || '');
              setJobRole(data.jobRole || '');
              setContactEmail(data.contactEmail || '');
              setContactPhone(data.contactPhone || '');
              setBusinessName(data.businessName || '');
              setBusinessAddress(data.businessAddress || '');
              setPostCode(data.postCode || '');
              setClubTimings(data.clubTimings || { from: '', to: '' });
              setLunchTime(data.lunchTime || { from: '', to: '' });
              setDeliveryWindow(data.deliveryWindow || { from: '', to: '' });
              setAgeRange(data.ageRange || { lower: '', upper: '' });
              setIsDeliveryWindowConfirmed(data.isDeliveryWindowConfirmed || false);
              setLegalName(data.legalName || '');
              setLegalAddress(data.legalAddress || '');
              setInvoiceEmail(data.invoiceEmail || '');
              setConfirmationChecked(data.confirmationChecked || false);
              if (data.venues && data.venues.length > 0) {
                setVenues(data.venues);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching document:", error);
          });
      }
    }, [auth, db]);

    const navigateToNextTab = () => {
      const currentIndex = tabs.indexOf(activeTab);
      const nextIndex = (currentIndex + 1) % tabs.length;
      setActiveTab(tabs[nextIndex]);
    };
    
    const handleUseMainContactChange = (index, useMainContact) => {
      const updatedVenues = [...venues];
      updatedVenues[index] = {
        ...updatedVenues[index],
        useMainContact,
        collectorName: useMainContact ? fullName : '',
        collectorEmail: useMainContact ? contactEmail : '',
        collectorPhone: useMainContact ? contactPhone : ''
      };
      setVenues(updatedVenues);
    };

    const handleSignOut = () => {
      const isConfirmed = window.confirm("Are you sure you want to sign out?");
      if (isConfirmed) {
        navigate('/');
      }
    };
    

    const navigateToHomePage = () => {
      navigate('/home');
    };

    const handleNewEmailChange = (e) => {
      setNewEmail(e.target.value);
      setEmailError('');
    };

    const validateEmailFormat = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    // const handleAddEmail = (e) => {
    //   e.preventDefault();
    //   if (validateEmailFormat(newEmail)) {
    //     setAllowedEmails([...allowedEmails, newEmail]);
    //     setNewEmail('');
    //   } else {
    //     setEmailError('Please enter a valid email address.');
    //   }
    // };

    // const handleDeleteEmail = (index) => {
    //   setAllowedEmails(allowedEmails.filter((_, i) => i !== index));
    // };

    const handleConfirmationChange = () => {
      setConfirmationChecked(!confirmationChecked);
      setConfirmationError('');
    };

    const switchTab = (tabName) => {
      setActiveTab(tabName);
    };

    const handleCheckboxChange = () => {
      setIsDeliveryWindowConfirmed(!isDeliveryWindowConfirmed);
    };

    const handleAddVenue = () => {
      setVenues([...venues, { postCode: '', venueNumber: '', instructions: '', type: 'Venue', deliveryOption: 'pickup', useMainContact: false, contactName: '', contactEmail: '', contactPhone: '' }]);
    };

    const handleRemoveVenue = (index) => {
      const newVenues = [...venues];
      newVenues.splice(index, 1);
      setVenues(newVenues);
    };

    const handleChangeVenue = (index, field, value) => {
      const newVenues = [...venues];
      newVenues[index][field] = value;
      setVenues(newVenues);
    };
    


    const handleSaveChanges = async (e) => {
      // const count =0;
      // count=count+1;
      // console.log("Here",count);
      e.preventDefault();
      

      // const isValid=validateTabData();
      // Assuming isValid function or logic is defined elsewhere and correctly validates all inputs
      // if (!isValid) {
      //   alert("Please fill in all required fields.");
      //   return;
      // }
    
      // Prepare the data object to match your Firestore schema
      const newData = {
        fullName,
        jobRole,
        contactEmail,
        contactPhone,
        businessName,
        businessAddress,
        postCode,
        venues: venues.map(venue => ({
          ...venue,
          // Ensure any transformation or additional logic you need for venues
        })),
        clubTimings,
        lunchTime,
        deliveryWindow,
        ageRange,
        isDeliveryWindowConfirmed,
        legalName,
        legalAddress,
        invoiceEmail,
        confirmationChecked,
      };
      console.log("New Data",newData);
      try {
        const user = auth.currentUser;
        if (!user) {
          alert("No user is signed in.");
          return;
        }
        const uid = user.uid;
    
        // Save the document
        await setDoc(doc(db, "ClientContactInfo", uid), newData, { merge: true });
        alert("Information saved successfully!");
        setIsBusinessInfoComplete(true); // Assuming you have logic to redirect or update UI based on this state
      } catch (error) {
        console.error("Error saving document:", error);
        alert("Failed to save information. Please try again.");
      }
    };
    
    // const isValid = validateTabData();
    // const isValid = validateTabData();

    return (
      <div className="business-info-container">
        <aside className="sidebar">
          <img src={yourLogo} alt="Your Logo" className="logo" />
          <nav className="nav">
            <ul>
              <li className="nav-item" onClick={navigateToHomePage}>Home Page</li>
              <li className={`nav-item ${activeTab === 'personalInfo' ? 'active' : ''}`} onClick={() => switchTab('personalInfo')}>Contact Details</li>
              <li className={`nav-item ${activeTab === 'businessInfo' ? 'active' : ''}`} onClick={() => switchTab('businessInfo')}>Club or School Information</li>
              <li className={`nav-item ${activeTab === 'invoiceDetails' ? 'active' : ''}`} onClick={() => switchTab('invoiceDetails')}>Invoice Details</li>
              <li className={`nav-item ${activeTab === 'additionalDetails' ? 'active' : ''}`} onClick={() => setActiveTab('additionalDetails')}>Additional Details</li>
              <li className="nav-item" onClick={handleSignOut}>Sign Out</li>
            </ul>
          </nav>
        </aside>
        <main className="content">
          {activeTab === 'personalInfo' && (
            <div>
              <h1>Contact Details</h1>
              <h3>Please provide the following information for the HAF program's point of contact. This information will be used to confirm your order and reach out to you regarding drop-off arrangements.</h3>
              <form onSubmit={handleSaveChanges} className="business-form">
                <label htmlFor="fullName">Full Name</label>
                <input type="text" id="fullName" name="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                <label htmlFor="jobRole">Job Role</label>
                <input type="text" id="jobRole" name="jobRole" value={jobRole} onChange={(e) => setJobRole(e.target.value)} required />
                <label htmlFor="contactEmail">Email Address</label>
                <input type="email" id="contactEmail" name="contactEmail" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} required />
                <label htmlFor="contactPhone">Phone Number</label>
                <input type="tel" id="contactPhone" name="contactPhone" value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} required />
                <button type="submit">Save Changes</button>
              </form>
            </div>
          )}
          {activeTab === 'businessInfo' && (
            <div className="business-info-tab">
              <h2>Club/School Information</h2>
              <form onSubmit={handleSaveChanges}>
                <div className="form-group">
                  <label htmlFor="businessName">School/Club Name</label>
                  <input type="text" id="businessName" name="businessName" value={businessName} onChange={(e) => setBusinessName(e.target.value)} required />
                </div>
                <div>
                  <h3>Venues</h3>
                  {venues.map((venue, index) => (
  <div key={index} className="venue">
    <div className="form-group">
      <label htmlFor={`deliveryOption-${index}`}>Logistics Option:</label>
      <select 
        id={`deliveryOption-${index}`}
        value={venue.deliveryOption} 
        onChange={(e) => handleChangeVenue(index, 'deliveryOption', e.target.value)} 
        required>
        <option value="">Select Delivery Option</option>
        <option value="pickup">Pickup</option>
        <option value="delivery">Delivery</option>
      </select>
    </div>
    {/* Address and Post Code fields are always included */}
    <div className="form-group">
      <label htmlFor={`venueAddress-${index}`}>Venue Address:</label>
      <input 
        type="text" 
        id={`venueAddress-${index}`}
        value={venue.address} 
        onChange={(e) => handleChangeVenue(index, 'address', e.target.value)} 
        required />
    </div>
    <div className="form-group">
      <label htmlFor={`postCode-${index}`}>Post Code:</label>
      <input 
        type="text" 
        id={`postCode-${index}`}
        value={venue.postCode} 
        onChange={(e) => handleChangeVenue(index, 'postCode', e.target.value)} 
        required />
    </div>
    {venue.deliveryOption === 'pickup' && (
      <>
        <div className="form-group">
          <label htmlFor={`contactName-${index}`}>Pickup Person's Name:</label>
          <input 
            type="text" 
            id={`contactName-${index}`}
            value={venue.contactName} 
            onChange={(e) => handleChangeVenue(index, 'contactName', e.target.value)} 
            required />
        </div>
        <div className="form-group">
          <label htmlFor={`venueContactEmail-${index}`}>Pickup Person Email:</label>
          <input 
            type="email" 
            id={`venueContactEmail-${index}`}
            value={venue.venueContactEmail} 
            onChange={(e) => handleChangeVenue(index, 'venueContactEmail', e.target.value)} 
            required />
        </div>
        <div className="form-group">
          <label htmlFor={`venueContactPhone-${index}`}>Pickup Person Phone Number:</label>
          <input 
            type="tel" 
            id={`venueContactPhone-${index}`}
            value={venue.venueContactPhone} 
            onChange={(e) => handleChangeVenue(index, 'venueContactPhone', e.target.value)} 
            required />
        </div>
      </>
    )}
    {venue.deliveryOption === 'delivery' && (
      <>
        <div className="form-group">
          <label htmlFor={`instructions-${index}`}>Delivery Instructions (If any):</label>
          <input 
            type="text" 
            id={`instructions-${index}`}
            value={venue.instructions} 
            onChange={(e) => handleChangeVenue(index, 'instructions', e.target.value)} 
            required />
        </div>
        <div className="form-group">
          <label>Contact details of person receiving delivery:</label>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id={`useMainContact-${index}`}
              checked={venue.useMainContact}
              onChange={(e) => handleUseMainContactChange(index, e.target.checked)}
            />
            <label htmlFor={`useMainContact-${index}`}>Use main contact details</label>
          </div>
        </div>
      </>
    )}
    <button type="button" onClick={() => handleRemoveVenue(index)}>Remove Venue</button>
  </div>
))}
<h4>For Multiple Locations, please add the venues below</h4>
                  <button onClick={handleAddVenue}>Add Venue</button>
                </div>
                <button type="submit">Save Changes</button>
              </form>
            </div>
          )}
          {activeTab === 'additionalDetails' && (
            <div className="additional-details">
              <h1>Additional Details</h1>
              <form onSubmit={handleSaveChanges}>
                <div className="form-group">
                  <label htmlFor="clubTimingsFrom">Club Timings - From:</label>
                  <input type="time" id="clubTimingsFrom" value={clubTimings.from} onChange={(e) => setClubTimings({ ...clubTimings, from: e.target.value })} required />
                  <span> to </span>
                  <input type="time" id="clubTimingsTo" value={clubTimings.to} onChange={(e) => setClubTimings({ ...clubTimings, to: e.target.value })} required />
                </div>
                <div className="form-group">
                  <label htmlFor="lunchTimeFrom">Lunch Time - From:</label>
                  <input type="time" id="lunchTimeFrom" value={lunchTime.from} onChange={(e) => setLunchTime({ ...lunchTime, from: e.target.value })} required />
                  <span> to </span>
                  <input type="time" id="lunchTimeTo" value={lunchTime.to} onChange={(e) => setLunchTime({ ...lunchTime, to: e.target.value })} required />
                </div>
                <div className="form-group">
                  <label htmlFor="deliveryWindow">Please confirm the delivery window of 9:45am - 11:45am</label>
                  <input
                    type="checkbox"
                    id="deliveryWindow"
                    checked={isDeliveryWindowConfirmed}
                    onChange={handleCheckboxChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="ageRangeLower">Age Range - Lower:</label>
                  <input type="number" id="ageRangeLower" value={ageRange.lower} onChange={(e) => setAgeRange({ ...ageRange, lower: e.target.value })} required />
                  <span> to </span>
                  <input type="number" id="ageRangeUpper" value={ageRange.upper} onChange={(e) => setAgeRange({ ...ageRange, upper: e.target.value })} required />
                </div>
                <button type="submit" className="save-changes-btn">Save Changes</button>
              </form>
            </div>
          )}
          {activeTab === 'invoiceDetails' && (
    <div className="invoice-details-container">
      <h1 className="invoice-details-title">Invoice Details</h1>
      <form onSubmit={handleSaveChanges} className="invoice-details-form">
        <div className="form-group">
          <label htmlFor="legalName" className="form-label">Legal Name</label>
          <input type="text" id="legalName" value={legalName} onChange={(e) => setLegalName(e.target.value)} required className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="legalAddress" className="form-label">Legal Address</label>
          <input type="text" id="legalAddress" value={legalAddress} onChange={(e) => setLegalAddress(e.target.value)} required className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="invoiceEmail" className="form-label">Invoice Email</label>
          <input type="email" id="invoiceEmail" value={invoiceEmail} onChange={(e) => setInvoiceEmail(e.target.value)} required className="form-input" />
        </div>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  )}

        </main>
      </div>
    );
          }  
  export default BusinessInfo;