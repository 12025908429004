import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalContext = createContext();

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  // Initialize state with value from local storage to maintain state across reloads
  const [isBusinessInfoComplete, setIsBusinessInfoComplete] = useState(() => {
    const saved = localStorage.getItem('isBusinessInfoComplete');
    return saved !== null ? JSON.parse(saved) : false;
  });

  // Update local storage whenever isBusinessInfoComplete changes
  useEffect(() => {
    localStorage.setItem('isBusinessInfoComplete', JSON.stringify(isBusinessInfoComplete));
  }, [isBusinessInfoComplete]);

  return (
    <GlobalContext.Provider value={{ isBusinessInfoComplete, setIsBusinessInfoComplete }}>
      {children}
    </GlobalContext.Provider>
  );
};
