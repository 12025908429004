import React, { useState, useEffect } from 'react';
import './Order.css';
import { useNavigate } from 'react-router-dom';
import logo from './ap-logo-4.png';
import { FaCartPlus, FaFileInvoice, FaSignature, FaThumbsUp, FaTruck, FaHome, FaSignOutAlt } from 'react-icons/fa';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { FaCartArrowDown, FaCalendarAlt, FaUtensils, FaRegClock, FaRegCheckCircle } from 'react-icons/fa'; // Add these imports at the top
import Invoice from './Invoice';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Orders = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('cartItems');
  const [orders, setOrders] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [priceBreakDown, setPriceBreakDown] = useState(null); // State to store price breakdown
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(null); // State to track which order's invoice to show
  const [priceBreakdown, setPriceBreakdown] = useState(null);
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    
    const fetchOrders = async () => {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const docRef = doc(db, "ClientContactInfo", uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setOrders(docSnap.data().orders || []);
            setIsLoaded(true);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        }
      }
    };
    fetchOrders();
  }, [auth, db]);
  
  const renderTabContent = () => {
    switch (activeTab) {
      case 'cartItems':
        return (
          <div>
            <h2>Your Cart Items</h2>
            {isLoaded ? (
              <div className="cart-items-container">
                {orders.map((order, index) => {
                  const numberOfDays = order.dateType === 'range' ? Math.round((new Date(order.to) - new Date(order.from)) / (1000 * 3600 * 24)) + 1 : 1;
                  const cutleryPerDay = order.cutlery ? order.cutleryQuantity * numberOfDays : 0;
                  const paperBowlPerDay = order.paperBowl ? order.paperBowlQuantity * numberOfDays : 0;
                  const plasticContainerPerDay = order.plasticContainer ? order.plasticContainerQuantity * numberOfDays : 0;
  
                  return (
                    <div className="cart-item" key={index}>
                      <FaCartArrowDown className="cart-icon" />
                      <div className="order-details">
                        <div className="order-detail">
                          <FaCalendarAlt /> Date: {order.date || `${order.from} to ${order.to}`}
                        </div>
                        {/* <div className="order-detail">
                          <FaUtensils /> Meal Type: {order.mealType}
                        </div> */}
                        <div className="order-detail">
                          <FaRegClock /> Total Meals: {order.totalMeals}
                        </div>
                        <div className="order-detail">
                          <FaRegCheckCircle /> Special Meals: {order.specialMeals}
                        </div>
                        <div className="order-detail">
                          <FaUtensils /> Total Cutlery: {cutleryPerDay}
                        </div>
                        <div className="order-detail">
                          <FaUtensils /> Total Paper Plates/Bowls: {paperBowlPerDay}
                        </div>
                        <div className="order-detail">
                          <FaUtensils /> Total Takeaway Containers: {plasticContainerPerDay}
                        </div>
                        <div className="order-detail"><FaHome /> Address: {order.address}</div>
                        <div className="order-detail"><FaMapMarkerAlt /> Postcode: {order.postcode}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : <p>Loading orders...</p>}
          </div>
        );
        break;
      case 'contractSign':
      case 'pendingApproval':
      case 'confirmedOrders':
        return (
          <div className="under-construction">
            <p>Under Construction</p>
            <img src="https://www.animatedimages.org/data/media/695/animated-under-construction-image-0035.gif" alt="Under Construction" />
          </div>
        );
    }
  };
  
  const navigateToHome = () => navigate('/home');
  const handleSignOut = () => {
    const isConfirmed = window.confirm("Are you sure you want to sign out?");
    if (isConfirmed) {
      navigate('/');
    }
  };
  const handleInvoice = () => navigate('/Invoice');
  return (
    <div className="orders-page">
      <aside className="orders-sidebar">
        <img src={logo} alt="Company Logo" className="sidebar-logo"/>
        <ul>
        <li onClick={navigateToHome}><FaHome /> Home</li>
          <li className={activeTab === 'cartItems' ? 'active' : ''} onClick={() => setActiveTab('cartItems')}><FaCartPlus /> Your Cart Items</li>
          <li className={activeTab === 'invoice' ? 'active' : ''} onClick={handleInvoice}><FaFileInvoice /> Invoice</li>
          <li className={activeTab === 'contractSign' ? 'active' : ''} onClick={() => setActiveTab('contractSign')}><FaSignature /> Contract Sign</li>
          <li className={activeTab === 'pendingApproval' ? 'active' : ''} onClick={() => setActiveTab('pendingApproval')}><FaThumbsUp /> Pending for Contract Approval</li>
          <li className={activeTab === 'confirmedOrders' ? 'active' : ''} onClick={() => setActiveTab('confirmedOrders')}><FaTruck /> Confirmed Orders</li>
          
          <li onClick={handleSignOut}><FaSignOutAlt /> Sign Out</li>
        </ul>
      </aside>
      <main className="orders-content">
        {renderTabContent()}
      </main>
    </div>
  );
};

export default Orders;