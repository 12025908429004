import React from 'react';
import './Home.css'; // Make sure you create a corresponding CSS file
import logo from './ap-logo-4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTemperatureLow } from '@fortawesome/free-solid-svg-icons'; // Import the temperature-related icon
import { faShoppingBasket, faTruck, faStar, faComments } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { faBuilding } from '@fortawesome/free-solid-svg-icons';


const Home = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    const isConfirmed = window.confirm("Are you sure you want to sign out?");
    if (isConfirmed) {
      navigate('/');
    }
  };
  const goToBusinessInfo = () => {
    navigate('/business-info'); // This is the route you will navigate to
  };

  // New function to handle navigation to Add Order
  const goToAddOrder = () => {
    navigate('/add-order');
  };
  const goToEditOrder = () => {
    navigate('/edit-order');
  };

  const handleTempTracker = () => 
  {
    navigate('/TempTracker');
  }
  // New function for navigating to Your Orders
  const goToOrders = () => {
    navigate('/orders'); // Adjust the path as needed
  };

  return (
    <div className="dashboard-container">
      <div className="top-nav">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h1 className="title">Akshaya Patra UK - Order Management Tool</h1>
        <button className="signout-button" onClick={handleSignOut}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span>Sign Out</span>
        </button>
        
      </div>
      <div className="dashboard-content">
        <div className="dashboard-grid">
          <div className="dashboard-item" onClick={goToBusinessInfo}>
            <FontAwesomeIcon icon={faBuilding} size="2x" className="dashboard-icon" />
            <p>Initial Mandatory Information</p>
          </div>
          <div className="dashboard-item" onClick={goToAddOrder}>
            <FontAwesomeIcon icon={faPlus} size="2x" className="dashboard-icon" />
            <p>Add Order</p>
          </div>
          <div className="dashboard-item" onClick={goToEditOrder}>
            <FontAwesomeIcon icon={faEdit} size="2x" className="dashboard-icon" />
            <p>Edit and View Orders</p>
          </div>
          <div className="dashboard-item" onClick={handleTempTracker}>
            <FontAwesomeIcon icon={faTemperatureLow} size="2x" className="dashboard-icon" />
            <p>Temperature Tracking</p>
          </div>
          <div className="dashboard-item" onClick={goToOrders}>
            <FontAwesomeIcon icon={faShoppingBasket} size="2x" className="dashboard-icon" />
            <p>Your Orders & Estimation</p>
          </div>
          <div className="dashboard-item under-development">
            <FontAwesomeIcon icon={faTruck} size="2x" className="dashboard-icon" />
            <p>Order Tracking</p>
          </div>
          <div className="dashboard-item under-development">
            <FontAwesomeIcon icon={faStar} size="2x" className="dashboard-icon" />
            <p>Complaints and Reviews</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;