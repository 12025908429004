import React, { useState, useEffect } from 'react';
import './EditOrder.css'; // Import the shared CSS file
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logo from './ap-logo-4.png'; // Import your logo image here

const EditOrder = () => {
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();
  const [orders, setOrders] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [postcodes, setPostcodes] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [numberOfDaysBlocking, setNumberOfDaysBlocking] = useState(0);

  useEffect(() => {
    const fetchPriceBreakDown = async () => {
      const priceBreakDownRef = doc(db, 'PriceBreakDown', 'Prices');
      const priceSnap = await getDoc(priceBreakDownRef);
      if (priceSnap.exists()) {
        setNumberOfDaysBlocking(priceSnap.data().NumberOfDaysBlocking);
      } else {
        console.error('No PriceBreakDown document!');
      }
    };

    fetchPriceBreakDown();

    const fetchVenues = async () => {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const docRef = doc(db, "ClientContactInfo", uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const venuesData = docSnap.data().venues;
            setPostcodes(venuesData.map(venue => venue.postCode));
            setAddresses(venuesData.map(venue => venue.address));
          }
        } catch (error) {
          console.error("Error fetching venues:", error);
        }
      }
    };
    const fetchOrders = async () => {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const docRef = doc(db, "ClientContactInfo", uid);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setOrders(docSnap.data().orders || []);
            setIsLoaded(true);
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        }
      }
    };
    fetchVenues();
    fetchOrders();
  }, [auth, db]);
  
  const handleDeleteOrder = async (index, date) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      const updatedOrders = [...orders];
      updatedOrders.splice(index, 1);
      setOrders(updatedOrders);

      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        try {
          // Update orders in Firebase
          await setDoc(doc(db, "ClientContactInfo", uid), { orders: updatedOrders }, { merge: true });
          alert("Order deleted successfully!");
        } catch (error) {
          console.error("Error updating document:", error);
        }
      }
    } else {
      // Show alert message if the order is within the blocking period
      if (isDateWithinBlockingPeriod(date)) {
        alert("You can't delete the order for dates within the blocking period. However, you can contact us at mealsupport@akshayapatra.org.uk to change or delete the order.");
      }
    }
  };

  const handleChange = (index, field, value) => {
    const updatedOrders = [...orders];
    updatedOrders[index][field] = value;
    setOrders(updatedOrders);
  };

  const SlideText = () => {
    return (
      <div className="slide-text">
        <p style={{ color: 'red' }}>You can't edit or delete the order for dates within 4 days. However, you can contact us at mealsupport@akshayapatra.org.uk to change or delete the order... .</p>
      </div>
    );
  };

  const handleSaveChanges = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("No user is signed in.");
      return;
    }
   
    const uid = user.uid;
    try {
      await setDoc(doc(db, "ClientContactInfo", uid), { orders, isApproved: false }, { merge: true });
      alert("Orders saved successfully!");
    } catch (error) {
      console.error("Error saving document:", error);
    }
  };

  const isDateWithinBlockingPeriod = (date) => {
    const currentDate = new Date();
    const orderDate = new Date(date);
    const diffTime = Math.abs(currentDate - orderDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= numberOfDaysBlocking;
  };

  return (
    <div className="edit-order-page">
      <aside className="aside-nav">
        <img src={logo} alt="Company Logo" className="sidebar-logo" />
        <button onClick={() => navigate('/home')}>Home</button>
        {/* Add more navigation buttons as needed */}
      </aside>
      <div className="edit-order-container">
      <SlideText />
        <h1>Edit Orders</h1>
        {isLoaded ? (
          <div className="orders-container">
            {orders.map((order, index) => (
              <div className="order-row" key={index}>
                {/* Display each order detail with editable inputs */}
                <label>
                  Date Range:
                  <select 
                    name="dateType" 
                    value={order.dateType} 
                    onChange={(e) => handleChange(index, 'dateType', e.target.value)}
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}>
                    <option value="individual">Individual Date</option>
                    <option value="range">Range of Dates</option>
                  </select>
                </label>
  
                {order.dateType === 'individual' ? (
                  <input 
                    type="date" 
                    name="date" 
                    value={order.date} 
                    onChange={(e) => handleChange(index, 'date', e.target.value)}
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                ) : (
                  <>
                    <input 
                      type="date" 
                      name="from" 
                      value={order.from} 
                      onChange={(e) => handleChange(index, 'from', e.target.value)} 
                      disabled={isDateWithinBlockingPeriod(order.date)} 
                      className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                    />
                    <input 
                      type="date" 
                      name="to" 
                      value={order.to} 
                      onChange={(e) => handleChange(index, 'to', e.target.value)} 
                      disabled={isDateWithinBlockingPeriod(order.date)} 
                      className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                    />
                  </>
                )}
                {/* New Dropdown for Postcode */}
                <label>
                  Delivery Postcode:
                  <select 
                    name="postcode" 
                    value={order.postcode} 
                    onChange={(e) => handleChange(index, 'postcode', e.target.value)}
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}>
                    {postcodes.map((code, idx) => (
                      <option key={idx} value={code}>{code}</option>
                    ))}
                  </select>
                </label>

                {/* Address Dropdown */}
                <label>
                  Delivery Address:
                  <select 
                    name="address" 
                    value={order.address} 
                    onChange={(e) => handleChange(index, 'address', e.target.value)}
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}>
                    {addresses.map((address, idx) => (
                      <option key={idx} value={address}>{address}</option>
                    ))}
                  </select>
                </label>
                
                <label>
                  Total Meals:
                  <input 
                    type="number" 
                    name="totalMeals" 
                    placeholder="Total Meals" 
                    value={order.totalMeals} 
                    onChange={(e) => handleChange(index, 'totalMeals', parseInt(e.target.value))} 
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                  <label className="min-meals-reminder">
                    Minimum order is 25 meals per day
                  </label>
                </label>
                <label>
                  Special Meals (Dairy Free and Gluten Free):
                  <input 
                    type="number" 
                    name="specialMeals" 
                    placeholder="Special Meals" 
                    value={order.specialMeals} 
                    onChange={(e) => handleChange(index, 'specialMeals', parseInt(e.target.value))} 
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                </label>
                <label>
                  Cutlery Per Day:
                  <input 
                    type="checkbox" 
                    name="cutlery" 
                    checked={order.cutlery} 
                    onChange={(e) => handleChange(index, 'cutlery', e.target.checked)} 
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                  <input 
                    type="number" 
                    name="cutleryQuantity" 
                    value={order.cutleryQuantity} 
                    onChange={(e) => handleChange(index, 'cutleryQuantity', parseInt(e.target.value))} 
                    disabled={!order.cutlery || isDateWithinBlockingPeriod(order.date)} 
                    className={!order.cutlery || isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                    placeholder="Quantity" 
                  />
                </label>
                <label>
                  Paper Bowl Per Day:
                  <input 
                    type="checkbox" 
                    name="paperBowl" 
                    checked={order.paperBowl} 
                    onChange={(e) => handleChange(index, 'paperBowl', e.target.checked)} 
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                  <input 
                    type="number" 
                    name="paperBowlQuantity" 
                    value={order.paperBowlQuantity} 
                    onChange={(e) => handleChange(index, 'paperBowlQuantity', parseInt(e.target.value))} 
                    disabled={!order.paperBowl || isDateWithinBlockingPeriod(order.date)} 
                    className={!order.paperBowl || isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                    placeholder="Quantity" 
                  />
                </label>
                <label>
                  Takeaway Container Per Day:
                  <input 
                    type="checkbox" 
                    name="plasticContainer" 
                    checked={order.plasticContainer} 
                    onChange={(e) => handleChange(index, 'plasticContainer', e.target.checked)} 
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                  <input 
                    type="number" 
                    name="plasticContainerQuantity" 
                    value={order.plasticContainerQuantity} 
                    onChange={(e) => handleChange(index, 'plasticContainerQuantity', parseInt(e.target.value))} 
                    disabled={!order.plasticContainer || isDateWithinBlockingPeriod(order.date)} 
                    className={!order.plasticContainer || isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                    placeholder="Quantity" 
                  />
                </label>
                <label>
                  Packing Instructions:
                  <textarea 
                    name="packingInstructions" 
                    placeholder="Packing Instructions" 
                    value={order.packingInstructions} 
                    onChange={(e) => handleChange(index, 'packingInstructions', e.target.value)} 
                    disabled={isDateWithinBlockingPeriod(order.date)} 
                    className={isDateWithinBlockingPeriod(order.date) ? 'input-disabled' : ''}
                  />
                </label>
                <button 
                  onClick={() => handleDeleteOrder(index, order.date)} 
                  disabled={isDateWithinBlockingPeriod(order.date)}
                >
                  Delete Order
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
    </div>
  );
};

export default EditOrder;
