import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import SignIn from './Signin';
import Signup from './Signup';
import Home from './Home';
import BusinessInfo from './BusinessInfo';
import AddOrder from './AddOrder';
import EditOrder from './EditOrder';
import Orders from './Order'; // Make sure to import the Orders component
import { GlobalProvider } from './GlobalContext'; // Import the provider
import reportWebVitals from './reportWebVitals';
import Invoice from './Invoice';
import TempTracker from './TempTracker';

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/home" element={<Home />} />
          <Route path="/business-info" element={<BusinessInfo />} />
          <Route path="/add-order" element={<AddOrder />} />
          <Route path="/edit-order" element={<EditOrder />} />
          <Route path='/orders' element={<Orders/>}/>
          <Route path='/Invoice' element={<Invoice/>}/>
                    {/* Add more routes for other pages as needed */}
          <Route path='/TempTracker' element={<TempTracker/>}/>          
                    
        </Routes>
      </Router>
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
