import React, { useState } from 'react';
import './Signup.css';
import logo from './ap-logo-4.png'; // Replace with the path to your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, collection, query, where, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { app } from './firebase'; // Make sure this points to your firebase config file

// Get a reference to the Firestore database
const db = getFirestore(app);
// Get a reference to the Firebase Auth service
const auth = getAuth(app);

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(e.target.value === confirmPassword ? '' : 'Passwords do not match.');
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError(e.target.value === password ? '' : 'Passwords do not match.');
  };

  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setPasswordError('');
    setSuccess('');

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    // Check if email is allowed
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    // Optionally store additional user information in Firestore (excluding password for security reasons)
    await addDoc(collection(db, "Users"), { email, uid: userCredential.user.uid });

    // Set success message
    setSuccess('User created successfully. You can now log in.');
    console.log('User created with UID:', userCredential.user.uid);
  } catch (error) {
    console.error('Error during sign up:', error);

    // Handle errors, including duplicate email
    setError(error.message);
  }

  };

  return (
    <div className="signup-wrapper">
      <div className="signup-container">
        <div className="signup-form-container">
          <img src={logo} alt="Logo" className="signup-logo"/>
          <h2>Welcome to Akshayapatra Uk Order Management Portal.</h2>
          <p className="already-signed-up">Already signed up? <a href="/">Log in</a></p>
          <form className="signup-form" onSubmit={handleSubmit}>
            <input type="email" value={email} onChange={handleEmailChange} placeholder="Your email address" required />
            <div className="password-container">
              <input type={passwordShown ? "text" : "password"} value={password} onChange={handlePasswordChange} placeholder="Create a password" required />
              <FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye} onClick={togglePasswordVisibility} className="password-icon"/>
            </div>
            {passwordError && <p className="password-error">{passwordError}</p>}
            <div className="password-container">
              <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder="Confirm password" required />
            </div>
            <button type="submit" className="signup-button">Sign up</button>
          </form>
          {error && <p className="signup-error">{error}</p>}
          {success && <p className="signup-success">{success}</p>}
        </div>
      </div>
    </div>
  );
};

export default Signup;
