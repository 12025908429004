import React, { useState, useEffect } from 'react';
import './AddOrder.css';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useGlobal } from './GlobalContext'; // Import the hook
import logoImage from './ap-logo-4.png';

const AddOrder = () => {
  const { isBusinessInfoComplete } = useGlobal(); // Use the global state
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [postcodes, setPostcodes] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedPostcode, setSelectedPostcode] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  useEffect(() => {
    if (!isBusinessInfoComplete) {
      navigate('/business-info');
    } 
    else {
      const fetchOrders = async () => {
        const user = auth.currentUser;
        if (user) {
          const uid = user.uid;
          const docRef = doc(db, "ClientContactInfo", uid);
          try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const data = docSnap.data();
              setOrders(data.orders || []);
              // Retrieve postcodes from venues array variable called postCode
              const postcodes = data.venues.map(venue => venue.postCode);
              setPostcodes(postcodes.filter((postcode, index, self) => self.indexOf(postcode) === index));
              // Retrieve addresses from venues array variable called venueNumber
              const addresses = data.venues.map(venue => venue.address).filter(Boolean);
              setAddresses([...new Set(addresses)]); // This will remove duplicates
            }
          } catch (error) {
            console.error("Error fetching document:", error);
          }
        }
      };
      fetchOrders();
    }
  }, [isBusinessInfoComplete, navigate, auth, db]);

  const handleAddField = () => {
    const newOrder = {
      dateType: 'individual', // Default to individual date type
      date: '',
      from: '',
      to: '',
      mealType: 'individual',
      totalMeals: '',
      specialMeals: '',
      cutlery: false,
      paperBowl: false,
      plasticContainer: false,
      packingInstructions: '',
      postcode: selectedPostcode,
      address: selectedAddress,
      isApproved: false
    };
  
    if (doesOrderExist(newOrder)) {
      alert("This postcode, address, and date combination already exists. Please go to the Edit Order page to make changes.");
      return;
    }
  
    setCurrentOrder(currentOrder.concat(newOrder));
  };
  
  const handleChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const updatedOrders = [...currentOrder];
    updatedOrders[index][name] = type === 'checkbox' ? checked : value;
  
    // Add validation for special meals being less than or equal to total meals
    if (name === 'specialMeals') {
      const specialMeals = parseInt(value, 10); // Convert value to integer
      const totalMeals = parseInt(updatedOrders[index]['totalMeals'], 10); // Convert total meals to integer
      if (specialMeals > totalMeals) {
        alert("Special meals cannot be greater than total meals.");
        return; // Prevent further updates if validation fails
      }
    }
  
    // If the field being changed is one of the keys we want to check for duplicates...
    if (['date', 'postcode', 'address'].includes(name)) {
      // Temporarily set the updated order details to check for duplicates
      if (doesOrderExist(updatedOrders[index])) {
        alert("This postcode, address, and date combination already exists. Please go to the Edit Order page to make changes.");
        return; // Prevent further updates if duplicate is found
      }
    }
  
    setCurrentOrder(updatedOrders);
  };
  
  
  const doesOrderExist = (newOrder) => {
    return orders.some(order => {
      const isPostcodeMatch = order.postcode === newOrder.postcode;
      const isAddressMatch = order.address === newOrder.address;
  
      if (!isPostcodeMatch || !isAddressMatch) {
        return false;
      }
  
      if (newOrder.dateType === 'individual') {
        // Check if the individual date matches any existing order's date or falls within any existing range
        const newOrderDate = new Date(newOrder.date);
        return order.date === newOrder.date || (order.dateType === 'range' && newOrderDate >= new Date(order.from) && newOrderDate <= new Date(order.to));
      } else if (newOrder.dateType === 'range') {
        // Check if any date within the new order's range overlaps with existing orders
        const newOrderFromDate = new Date(newOrder.from);
        const newOrderToDate = new Date(newOrder.to);
  
        if (order.dateType === 'individual') {
          const existingOrderDate = new Date(order.date);
          return existingOrderDate >= newOrderFromDate && existingOrderDate <= newOrderToDate;
        } else if (order.dateType === 'range') {
          const existingOrderFromDate = new Date(order.from);
          const existingOrderToDate = new Date(order.to);
          // Check if the new order's range overlaps with the existing order's range
          return newOrderFromDate <= existingOrderToDate && newOrderToDate >= existingOrderFromDate;
        }
      }
      return false;
    });
  };
  const handleRangeDateChange = (index, e) => {
    // Similar to handleChange, but specifically handle range date changes
    const { name, value } = e.target; // Assuming you have a way to set which field ('from' or 'to') is being edited
    const updatedOrder = { ...currentOrder[index], [name]: value };
  
    if (updatedOrder.from && updatedOrder.to) { // Only check if both dates are selected
      if (doesOrderExist(updatedOrder)) {
        alert("An order with the selected date range, postcode, and address combination already exists. Please adjust the dates or go to the Edit Order page to make changes.");
        return;
      }
    }
  
    setCurrentOrder(currentOrder.map((order, i) => i === index ? updatedOrder : order));
  };
  
  
  
  const handleRemoveField = (indexToRemove) => {
    setCurrentOrder(currentOrder.filter((_, index) => index !== indexToRemove));
  };

  const handleSaveChanges = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("No user is signed in.");
      return;
    }
    const uid = user.uid;
  
    // Check if all required fields are filled for both individual dates and ranges
    const allFieldsFilled = currentOrder.every(order => {
      // Check for individual date fields
      if (order.dateType === 'individual') {
        return order.date && order.postcode && order.address && order.totalMeals && order.specialMeals;
      }
      // Check for range date fields
      if (order.dateType === 'range') {
        return order.from && order.to && order.postcode && order.address && order.totalMeals && order.specialMeals;
      }
      return false; // If neither condition is met, return false
    });
  
    if (!allFieldsFilled) {
      alert("Please fill in all mandatory fields before saving.");
      return;
    }
  
    try {
      const combinedOrders = [...orders, ...currentOrder];
      await setDoc(doc(db, "ClientContactInfo", uid), { orders: combinedOrders }, { merge: true });
      const confirmation = window.confirm("Orders saved successfully! Do you want to clear the screen?");
      if (confirmation) {
        setOrders(combinedOrders);
        setCurrentOrder([]); // Clear the current order after saving
      }
    } catch (error) {
      console.error("Error saving document:", error);
    }
  };
  

  return (
    <div className="add-order-page">
      <header className="header">
        <img src={logoImage} alt="Logo" className="logo" />
        <nav className="header-nav">
          <button onClick={() => navigate('/home')}>Home</button>
          <button onClick={() => navigate('/')}>Sign Out</button>
        </nav>
      </header>
      <div className="add-order-container">
        <h1>Create New Order</h1>
        
        {currentOrder.map((order, index) => (
          <div className="order-row" key={index}>
            <select name="dateType" value={order.dateType} onChange={(e) => handleChange(index, e)}>
              <option value="individual">Individual Date</option>
              <option value="range">Range of Dates</option>
            </select>
            {order.dateType === 'individual' ? (
              <input type="date" name="date" value={order.date} onChange={(e) => handleChange(index, e)} />
            ) : (
              <>
                <input type="date" name="from" value={order.from} onChange={(e) => handleChange(index, e)} />
                <input type="date" name="to" value={order.to} onChange={(e) => handleChange(index, e)} />
              </>
            )}
            <select name="postcode" value={order.postcode || ''} onChange={(e) => handleChange(index, e)}>
              <option value="">Select Postcode</option>
              <option value="Pick up">Pick up</option>
              {postcodes.map((postcode, i) => (
                <option key={i} value={postcode}>{postcode}</option>
              ))}
            </select>
            <select name="address" value={order.address || ''} onChange={(e) => handleChange(index, e)}>
              <option value="">Select Address</option>
              <option value="Pick up">Pick up</option>
              {addresses.map((address, i) => (
                <option key={i} value={address}>{address}</option>
              ))}
            </select>
            <input 
              type="number" 
              name="totalMeals" 
              placeholder="Total Meals" 
              value={order.totalMeals} 
              onChange={(e) => handleChange(index, e)} 
            />
            <label className="min-meals-reminder">
              Minimum order is 25 meals per day
            </label>
            <input 
              type="number" 
              name="specialMeals" 
              placeholder="Special Meals [Dairy Free/Gluten Free]" 
              value={order.specialMeals} 
              onChange={(e) => handleChange(index, e)} 
            />
            <label>
              <input 
                type="checkbox" 
                name="cutlery" 
                checked={order.cutlery} 
                onChange={(e) => handleChange(index, e)} 
              />
              Cutlery Per day
            </label>
            {order.cutlery && (
              <input 
                type="number" 
                name="cutleryQuantity" 
                value={order.cutleryQuantity} 
                onChange={(e) => handleChange(index, e)} 
                placeholder="Quantity" 
              />
            )}
            <label>
              <input 
                type="checkbox" 
                name="paperBowl" 
                checked={order.paperBowl} 
                onChange={(e) => handleChange(index, e)} 
              />
              Paper Bowl/Plates Per day
            </label>
            {order.paperBowl && (
              <input 
                type="number" 
                name="paperBowlQuantity" 
                value={order.paperBowlQuantity} 
                onChange={(e) => handleChange(index, e)} 
                placeholder="Quantity" 
              />
            )}
            <label>
              <input 
                type="checkbox" 
                name="plasticContainer" 
                checked={order.plasticContainer} 
                onChange={(e) => handleChange(index, e)} 
              />
              Takeaway Container Per day
            </label>
            {order.plasticContainer && (
              <input 
                type="number" 
                name="plasticContainerQuantity" 
                value={order.plasticContainerQuantity} 
                onChange={(e) => handleChange(index, e)} 
                placeholder="Quantity" 
              />
            )}
            <textarea 
              name="packingInstructions" 
              placeholder="Packing Instructions" 
              value={order.packingInstructions} 
              onChange={(e) => handleChange(index, e)} 
            />
            <button onClick={() => handleRemoveField(index)}>Remove</button>
          </div>
        ))}
        <button onClick={handleAddField}>Add New Order</button>
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
    </div>
  );
};
export default AddOrder;