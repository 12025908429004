import React, { useState, useEffect } from 'react';
import './TempTracker.css';
import { Line } from 'react-chartjs-2';
import { getDatabase, ref, onValue } from 'firebase/database';
import moment from 'moment';
import logo from './ap-logo-4.png'; // Import your logo image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const TempTracker = () => {
  const [temperatures, setTemperatures] = useState([]);
  const navigate = useNavigate();

  const db = getDatabase();

  useEffect(() => {
    const temperaturesRef = ref(db, 'temperatures/');
    const unsubscribe = onValue(temperaturesRef, (snapshot) => {
      const data = snapshot.val();
      const newTemperatures = data ? Object.keys(data).map(key => ({
        time: moment(data[key].time, 'HH:mm:ss').toDate(),
        temperature: data[key].temperature
      })) : [];
      
      setTemperatures(newTemperatures);
    });

    // Unsubscribe from the changes on component unmount
    return () => unsubscribe();
  }, []);

  const chartData = {
    labels: temperatures.map(temp => temp.time),
    datasets: [
      {
        label: 'Temperature',
        data: temperatures.map(temp => temp.temperature),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          tooltipFormat: 'HH:mm:ss'
        },
        title: {
          display: true,
          text: 'Time'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Temperature (°C)'
        }
      }
    },
    maintainAspectRatio: false
  };

  const goToHome = () => {
    navigate('/home');
  };

  const handleSignOut = () => {
    const isConfirmed = window.confirm("Are you sure you want to sign out?");
    if (isConfirmed) {
      // Perform signout logic here
      navigate('/');
    }
  };

  return (
    <div className="temp-tracker-container">
      <div className="top-nav">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div>
          <button className="signout-button" onClick={handleSignOut}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Sign Out</span>
          </button>
          <button className="home-button" onClick={goToHome}>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </button>
        </div>
      </div>
      <h2>Temperature Tracker</h2>
      <div className="chart-container">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default TempTracker;
