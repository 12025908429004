import React, { useState, useEffect } from 'react';
import './Invoice.css';
import { useNavigate } from 'react-router-dom';
import logo from './ap-logo-4.png';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// Import Font Awesome components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTruck, faUtensils, faClipboardList, faHome, faTag, faCut, faBowlRice, faBox } from '@fortawesome/free-solid-svg-icons';

const Invoice = () => {
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();
  const [orders, setOrders] = useState([]);
  const [priceBreakdown, setPriceBreakdown] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
     const fetchPriceBreakdownAndOrders = async () => {
      try {
        // Fetch price breakdown
        const priceBreakdownDoc = await getDoc(doc(db, "PriceBreakDown", "Prices"));
        if (priceBreakdownDoc.exists()) {
          setPriceBreakdown(priceBreakdownDoc.data());
        }

        // Fetch user-specific orders
        const user = auth.currentUser;
        if (user) {
          const ordersDoc = await getDoc(doc(db, "ClientContactInfo", user.uid));
          if (ordersDoc.exists()) {
            setOrders(ordersDoc.data().orders || []);
            setIsLoaded(true);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPriceBreakdownAndOrders();
  }, [db, auth]);

  const calculateInvoiceForOrder = (order) => {
    
    const totalDays = order.dateType === 'range' ? 
      Math.round((new Date(order.to) - new Date(order.from)) / (1000 * 3600 * 24)) + 1 : 
      1;
      let deliveryCost = 0;
    // Extract prices
    const { BulkMealsPrice, NapkinPrice, BowlPrice, ContainerPrice, DeliveryCost } = priceBreakdown;
    const isPickup = order.address.toLowerCase() === 'pick up' || order.postcode.toLowerCase() === 'pick up';
    // Calculate costs
    
    const totalMealsCost = order.totalMeals * totalDays * BulkMealsPrice;
    // const deliveryCost = order.totalMeals * totalDays * DeliveryCost;
    // Calculate delivery cost only if it is not 'Pick up'
  if (!isPickup) {
    deliveryCost = order.totalMeals * totalDays * DeliveryCost;
  }
    const cutleryCost = (order.cutleryQuantity || 0) * NapkinPrice * totalDays;
    const bowlCost = (order.paperBowlQuantity || 0) * BowlPrice * totalDays;
    const containerCost = (order.plasticContainerQuantity || 0) * ContainerPrice * totalDays;
    const totalCost = totalMealsCost + cutleryCost + bowlCost + containerCost + deliveryCost;

    return { totalDays, totalMealsCost, cutleryCost, bowlCost, containerCost, deliveryCost, totalCost };
  };

  const renderInvoices = () => {
    let grandTotal = 0; // Initialize grand total of all orders
    
    // Extract individual prices from the priceBreakdown state
    const { BulkMealsPrice, NapkinPrice, BowlPrice, ContainerPrice, DeliveryCost } = priceBreakdown;
    
    const invoiceElements = orders.map((order, index) => {
      const invoiceDetails = calculateInvoiceForOrder(order);
      grandTotal += invoiceDetails.totalCost; // Accumulate the grand total
    
      // Determine the date or date range display based on the order's dateType
      const dateDisplay = order.dateType === 'range' ? 
        `From ${order.from} to ${order.to}` : 
        `Date: ${order.date}`;
    
      return (
        <div className="invoice-item" key={index}>
          <div className="order-details">
          <div className="order-detail"><FontAwesomeIcon icon={faCalendarAlt} /> {order.dateType === 'range' ? `From ${order.from} to ${order.to}` : `Date: ${order.date}`}</div>
          <div className="order-detail"><FontAwesomeIcon icon={faClipboardList} /> Number of Days: {invoiceDetails.totalDays}</div>
          <div className="order-detail"><FontAwesomeIcon icon={faUtensils} /> Total Meals (per day): {order.totalMeals}</div>
          <div className="order-detail"><FontAwesomeIcon icon={faTag} /> Total Meals Cost: £{invoiceDetails.totalMealsCost.toFixed(2)} (at £{BulkMealsPrice.toFixed(2)} each)</div>
          <div className="order-detail"><FontAwesomeIcon icon={faCut} /> Cutlery Cost: £{invoiceDetails.cutleryCost.toFixed(2)} (at £{NapkinPrice.toFixed(2)} each)</div>
          <div className="order-detail"><FontAwesomeIcon icon={faBowlRice} /> Paper Plates/Bowl Cost: £{invoiceDetails.bowlCost.toFixed(2)} (at £{BowlPrice.toFixed(2)} each)</div>
          <div className="order-detail"><FontAwesomeIcon icon={faBox} /> Takeaway Container Cost: £{invoiceDetails.containerCost.toFixed(2)} (at £{ContainerPrice.toFixed(2)} each)</div>

          <div className="order-detail"><FontAwesomeIcon icon={faHome} /> Address: {order.address || 'N/A'}</div>
          <div className="order-detail"><FontAwesomeIcon icon={faHome} /> Postcode: {order.postcode || 'N/A'}</div>
          <div className="order-detail"><FontAwesomeIcon icon={faTruck} /> Delivery Cost: £{invoiceDetails.deliveryCost.toFixed(2)} (at £{DeliveryCost.toFixed(2)} per meal)</div>
          
            <h3>Total Cost: £{invoiceDetails.totalCost.toFixed(2)} <span>[Inclusive of VAT]</span></h3>
            <p>Refer another HAF Club and receive £50.00 off on your invoice</p>
          </div>
        </div>
      );
    });
  
    return (
      <>
        <h2>Order Estimation</h2>
        {invoiceElements}
        <div className="grand-total">Grand Total of All Orders: £{grandTotal.toFixed(2)}<span>[Inclusive of VAT]</span></div>
        <p> 
        **The costs provided above serve as an approximation based on your current order specifics. However, the invoice amount may fluctuate later on due to alterations in meal quantities, among other factors. Kindly note that the invoice will be dispatched by our Finance Department closer to the conclusion of our HAF service.
</p>
      </>
    );
  };
    

  return (
    <div className="orders-page">
      <aside className="orders-sidebar">
        <img src={logo} alt="Company Logo" className="sidebar-logo"/>
        <ul>
          <li onClick={() => navigate('/home')}>Home</li>
        </ul>
      </aside>
      <main className="orders-content">
        {isLoaded ? renderInvoices() : <p>Loading invoices...</p>}
      </main>
    </div>
  );
};

export default Invoice;
