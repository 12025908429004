import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import './Signin.css';
import logo from './ap-logo-4.png';
import { app } from './firebase';

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const auth = getAuth(app);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      setError('Failed to sign in. Please check your email and password.');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div className="signin-wrapper">
      <div className="signin-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2 className="login-header">Login to Your Account</h2>
        <p className="divider">OR</p>
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
            required
          />
          <div className="form-footer">
            <button type="submit" className="signin-button">Sign In</button>
            <button type="button" onClick={handleForgotPassword} className="forgot-password">
              Forgot password?
            </button>
          </div>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
      <div className="signup-section">
        <h2 className="signup-header">New Here?</h2>
        <p className="signup-text">You can create an account here, if you registered your mail with us</p>
        <Link to="/signup" className="signup-button">Sign Up</Link>
      </div>
    </div>
  );
};

export default SignIn;
