import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"; // Import Realtime Database

const firebaseConfig = {
  apiKey: "AIzaSyAUeKHU29XqAqcV2in6OFj1KVN17HCC8Ek",
  authDomain: "tapf-sample.firebaseapp.com",
  databaseURL: "https://tapf-sample-default-rtdb.firebaseio.com",
  projectId: "tapf-sample",
  storageBucket: "tapf-sample.appspot.com",
  messagingSenderId: "284041167545",
  appId: "1:284041167545:web:ae3c8e005a418b43511168",
  measurementId: "G-FGCVELPKBL"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app); // This is how you initialize Realtime Database

export { app, analytics, db };
